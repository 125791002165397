/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

*/
import React from "react";
import withNavigation from "../withNavigation";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import SimpleNavBar from "components/Navbars/SimpleNavBar";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class VerificationSuccessView extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    setTimeout(() => {
      this.props.navigate("/login");
    }, 5000);

  };

  handleManualRedirect = () => {
    this.props.navigate("/login");
  };

  render() {
    return (
      <>
        <SimpleNavBar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-success pb-5">
                      <div className="text-muted text-center mb-3">
                        <h1 classname="display-1">
                            <i className="ni ni-check-bold" style={{ color: 'white'}}/>
                        </h1>
                        <h1 classname="display-1" style={{ color: 'white'}}>
                            Cuenta verificada
                        </h1>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>En un momento podrás iniciar sesión...</small>
                      </div>
                      <div className="text-center">
                        <small>
                          <a href="#" onClick={(e) => { e.preventDefault(); this.handleManualRedirect(); }}>
                            Haz clic aquí si no eres redirigido automáticamente
                          </a>
                        </small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default withNavigation(VerificationSuccessView);
