/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

*/
import React from "react";
import withNavigation from "../withNavigation";
import { useParams } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";

// core components
import SimpleNavBar from "components/Navbars/SimpleNavBar";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import ValidationService from "services/ValidationService.js";

class VerificationCheckView extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    this.verifyToken();
  };

  verifyToken = async() => {
    const { token } = this.props
    try {
        const response = await ValidationService.validateToken(token);
        if (response.status === 200) {
          this.props.navigate("/validation-success");
        } else {
          this.props.navigate("/validation-error");
        }
      } catch (error) {
        this.props.navigate("/validation-error");
      }
  };
  render() {
    return (
      <>
        <SimpleNavBar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-info pb-5">
                      <div className="text-muted text-center mb-3">
                      <h1 classname="display-1">
                        <Spinner color="white" style={{ width: '2rem', height: '2rem' }} /> 
                        </h1>
                      
                        <h1 classname="display-1" style={{ color: 'white'}}>
                            Verificando link...
                        </h1>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Este proceso puede tardar unos segundos.</small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

const VerificationCheckViewWrapper = (props) => {
  const { token } = useParams();
  return <VerificationCheckView {...props} token={token} />;
}

export default withNavigation(VerificationCheckViewWrapper);
