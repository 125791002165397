import httpClient from "./HttpClient";

const ValidationService = {
    validateToken: async (token) => {
        try {
            const response = await httpClient.get(`/validate/${token}`);
            return response;
        } catch (error) {
            throw error;
        }
    },
};

export default ValidationService;